import { Link } from "react-router-dom";
import './ButtonHollow.css';

function ButtonHollow({ children, href, onClick })
{
    const button = () =>
    {
        return (
            <div className="hollowButton">
                <Link to={href} style={{ textDecoration: 'none' }} onClick={onClick}>
                    {children}
                </Link>
            </div>
        )
    }

    return button();
}

export default ButtonHollow;