import './About.css';
import AboutEducation from './AboutEducation.js';
import AboutPortfolio from './AboutPortfolio.js';
import AboutHeader from './AboutHeader.js';

import * as Icon from 'react-bootstrap-icons';
import ProfilePic from './assets/NicklasBehrndtz_Billede_Kvadrat.png';
import ButtonHollow from './components/ButtonHollow.js';
import ButtonHome from './components/ButtonHome.js';
import Footer from './Footer.js';
import AboutSkills from './AboutSkills.js';

const About = () =>
{
  return (
    <>
    <AboutHeader />
    
    <AboutEducation />
    
    <AboutSkills />
    
    <AboutPortfolio />
    
    <Footer />
    </>
  )
}

export default About