import React, { useEffect, useState } from 'react'
import './AboutPortfolio.css'
import Card from './components/Card.js'
import greenNoise from './assets/background_mintGreen_noise.png'
import { useSpring, a, easings } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
import * as Icon from 'react-bootstrap-icons';
import CategoryBox from './components/CategoryBox.js'
import Label from './components/Label.js'

import ItchBadge from './assets/badge-color.png'
import PopupBox from './components/PopupBox.js'

import { portInfo } from './assets/data.js'
import CustomCarousel from './components/CustomCarousel.js'
import CustomVideo from './components/CustomVideo.js'

const AboutPortfolio = () =>
{
  const [bounce1, b1] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
  const [bounce2, b2] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
  const [bounce3, b3] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));

  setTimeout(() =>
  {
    b1.start({
      from: { transform: 'scale(1.0)' },
      to: { transform: 'scale(1.01)' },
      loop: { reverse: true },
    })
  }, 50);

  setTimeout(() =>
  {
    b2.start({
      from: { transform: 'scale(1.0)' },
      to: { transform: 'scale(1.03)' },
      loop: { reverse: true },
    })
  }, 100);

  setTimeout(() =>
  {
    b3.start({
      from: { transform: 'scale(1.0)' },
      to: { transform: 'scale(1.04)' },
      loop: { reverse: true },
    })
  }, 150);

  const [titleSpring, titleS] = useSpring(() => ({
    from: { letterSpacing: '5rem', textShadow: '0 0 30px black' },
    config: { mass: 5, tension: 1000, friction: 300 }
  }));

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.75,
    triggerOnce: true
  });

  /* TITLE ANIMATION START */
  useEffect(() =>
  {
    if (inView)
    {
      titleS.start({
        to: { letterSpacing: '0px', textShadow: '0 0 0 white' }
      });
    }
    else
    {
      titleS.start({
        from: { letterSpacing: '30px' }
      });
    }
  });

  const [showPopup, setShowPopup] = useState(false);
  const [chosenItem, setChosenItem] = useState(0);
  const handleReadClick = () => { console.log("Show popup!"); setShowPopup(true) };
  const handleCloseModal = () => { setShowPopup(false) }

  const portfolioItemsLeft = portInfo.map((info, index) =>
    (index % 3 == 0) ? (
      <CategoryBox iconElement={info.icon ?? <Icon.Pencil size={'auto'} />}>
        {
          (info.previewImage.replace(/ .*/, '') === 'video') ?
            <CustomVideo pauseOutOfView={true} src={info.previewImage.replace(/video /, '')} autoPlay={true} muted={true} loop={true} width={'100%'} style={{ position: 'absolute', width: '101%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> :
            <img src={info.previewImage.replace(/image /, '')} width={'100%'} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} />
        }
        {/* <video src={'/graphics/portfolio_preview.mp4'} autoPlay={true} muted={true} loop={true} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> */}
        <div style={{ zIndex: 1, color: 'var(--offWhite)', textAlign: 'center' }}>
          <Label color='var(--offWhite)' size={2}>{info.title}</Label>
          <p style={{ whiteSpace: 'pre-line' }}>
            {info.subtitle}
          </p>
          <a onClick={() => { setChosenItem(index); handleReadClick(); }} className='btn btn-primary btn-lg' data-bs-toggle="modal" data-bs-target="#exampleModal">Read more</a>
          <a hidden={info.itchUrl == null} href={info.itchUrl} className='btn btn-itch' target='_blank' style={{ position: 'absolute', bottom: '8px', right: '4px' }}><img src={ItchBadge} height={40} /></a>
        </div>
      </CategoryBox>
    ) :
      null
  )
  const portfolioItemsMiddle = portInfo.map((info, index) =>
    (index % 3 == 1) ? (
      <CategoryBox iconElement={info.icon ?? <Icon.Pencil size={'auto'} />}>
        {
          (info.previewImage.replace(/ .*/, '') === 'video') ?
            <CustomVideo pauseOutOfView={true} src={info.previewImage.replace(/video /, '')} autoPlay={true} muted={true} loop={true} style={{ position: 'absolute', width: '101%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> :
            <img src={info.previewImage.replace(/image /, '')} width={'100%'} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} />
        }
        {/* <video src={'/graphics/portfolio_preview.mp4'} autoPlay={true} muted={true} loop={true} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> */}
        <div style={{ zIndex: 1, color: 'var(--offWhite)', textAlign: 'center' }}>
          <Label color='var(--offWhite)' size={2}>{info.title}</Label>
          <p style={{ whiteSpace: 'pre-line' }}>
            {info.subtitle}
          </p>
          <a onClick={() => { setChosenItem(index); handleReadClick(); }} className='btn btn-primary btn-lg' data-bs-toggle="modal" data-bs-target="#exampleModal">Read more</a>
          <a hidden={info.itchUrl == null} href={info.itchUrl} className='btn btn-itch' target='_blank' style={{ position: 'absolute', bottom: '8px', right: '4px' }}><img src={ItchBadge} height={40} /></a>
        </div>
      </CategoryBox>
    ) :
      null
  )
  const portfolioItemsRight = portInfo.map((info, index) =>
    (index % 3 == 2) ? (
      <CategoryBox iconElement={info.icon ?? <Icon.Pencil size={'auto'} />}>
        {
          (info.previewImage.replace(/ .*/, '') === 'video') ?
            <CustomVideo pauseOutOfView={true} src={info.previewImage.replace(/video /, '')} autoPlay={true} muted={true} loop={true} style={{ position: 'absolute', width: '101%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> :
            <img src={info.previewImage.replace(/image /, '')} width={'100%'} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} />
        }
        {/* <video src={'/graphics/portfolio_preview.mp4'} autoPlay={true} muted={true} loop={true} style={{ position: 'absolute', width: '100%', top: '0', right: '0', bottom: '0', left: '0', margin: 'auto', zIndex: 0, filter: 'brightness(0.5)' }} /> */}
        <div style={{ zIndex: 1, color: 'var(--offWhite)', textAlign: 'center' }}>
          <Label color='var(--offWhite)' size={2}>{info.title}</Label>
          <p style={{ whiteSpace: 'pre-line' }}>
            {info.subtitle}
          </p>
          <a onClick={() => { setChosenItem(index); handleReadClick(); }} className='btn btn-primary btn-lg' data-bs-toggle="modal" data-bs-target="#exampleModal">Read more</a>
          <a hidden={info.itchUrl == null} href={info.itchUrl} className='btn btn-itch' target='_blank' style={{ position: 'absolute', bottom: '8px', right: '4px' }}><img src={ItchBadge} height={40} /></a>
        </div>
      </CategoryBox>
    ) :
      null
  )

  return (
    <div className='section-portfolio'>

      {/* Background Video */}
      <CustomVideo pauseOutOfView={true} className='portfolio-videoPreview' src={'/graphics/portfolio_preview.mp4'} autoPlay={true} muted={true} loop={true} />

      {/* Background circles */}
      <div className='header-circle' id='circleGrey3'></div>
      <div className='header-circle' id='circleGrey2'></div>
      <div className='header-circle' id='circleGrey1'></div>

      <a.h1 className='title-portfolio' ref={ref} style={titleSpring}>PORTFOLIO</a.h1>

      <div className='container-fluid' style={{ zIndex: '1' }}>

        <p className='description-portfolio' style={{ fontSize: '18px', textShadow: '1px 1px 2px #000000f0', background: '#00000040', borderRadius: '20px 20px 5px 5px', padding: '1rem 0rem' }}>
          Below here you will find most of my works from 2016 onward.<br />
          The origin of the work ranges from <Icon.Book className='oT' /> <span className='oT'>university projects</span> to <br />
          <Icon.Brush className='oT' /> <span className='oT'>hobby projects</span> and <Icon.Flag className='oT' /> <span className='oT'>volunteer work</span>.
        </p>

        <div className='row'>
          <div className='col'>{portfolioItemsLeft}</div>
          <div className='col'>{portfolioItemsMiddle}</div>
          <div className='col'>{portfolioItemsRight}</div>
        </div>

        <PopupBox visible={showPopup} closeHandle={handleCloseModal} title={<>{portInfo[chosenItem].icon} {portInfo[chosenItem].title}</>}>
          {/* <p style={{whiteSpace: 'pre-line'}}>{portInfo[chosenItem].subtitle}</p> */}
          <div className='row'>
            <div className='col'>
              {portInfo[chosenItem].information}
            </div>
            <div className='col-5'>
              <div style={{ borderRadius: '5px', overflow: 'hidden' }}>
                {(portInfo[chosenItem].images != null) ? <CustomCarousel images={portInfo[chosenItem].images} /> : null}
                <div className='px-3 py-2' style={{ background: '#222', color: 'var(--offWhite)' }}>
                  {(portInfo[chosenItem].timeSpent == null) ? null : <b>Time spent:</b>}
                  <p>{portInfo[chosenItem].timeSpent}</p>
                  {(portInfo[chosenItem].programs == null) ? null : <b>Made with:</b>}
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}> {/* PROGRAM LIST */}
                    {(portInfo[chosenItem].programs != null) ? portInfo[chosenItem].programs.map(program => <div className='mx-1'>{program}</div>) : null}
                  </div>
                  {(portInfo[chosenItem].languages == null) ? null : <b>Languages:</b>}
                  <ul> {/* LANGUAGE LIST */}
                    {(portInfo[chosenItem].languages != null) ? portInfo[chosenItem].languages.map(language => <li style={{ fontFamily: 'Roboto' }}>{language}</li>) : null}
                  </ul>
                  {(portInfo[chosenItem].contribution == null) ? null : <b>Contribution:</b>}
                  <p>{portInfo[chosenItem].contribution}</p>
                </div>
              </div>
            </div>
          </div>
        </PopupBox>

      </div>
    </div>
  )
}

export default AboutPortfolio