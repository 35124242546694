import React, { useRef, useState } from 'react'
import './AboutSkills.css';
import * as Badges from './components/Badges.js'
import * as Logos from './components/Logos.js'
import RatingCard from './components/RatingCard.js';
import * as Icon from 'react-bootstrap-icons';
import { skillLanguages, skillDisciplines, skillPrograms } from './assets/data.js';

const AboutSkills = () =>
{
    const sectionRef = useRef(null);
    const [expandSection, setExpandSection] = useState(false);

    const handleExpand = () =>
    {
        setExpandSection(!expandSection);

        if (expandSection)
        {
            // Scroll to top of the section
            sectionRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
        }
    };

    const skillItemsToShow = 4;

    const languages = skillLanguages.map((info, index) =>
    {
        return <>
            <RatingCard
                expand={false}
                title={info.name}
                rating={info.rating}
                tip={<><Icon.Clock style={{ filter: 'drop-shadow(1px 1px 0px black' }} /> {info.time}</>}
                icon={info.icon}
                desc={info.description === "" || info.description == null ? 'No description for this yet...' : info.description}
                hidden={(index >= skillItemsToShow ? !expandSection : false)}
            />
        </>
    })

    const disciplines = skillDisciplines.map((info, index) =>
    {
        return <>
            <RatingCard
                expand={false}
                title={info.name}
                rating={info.rating}
                tip={info.time}
                icon={info.icon}
                desc={info.description === "" || info.description == null ? 'No description for this yet...' : info.description}
                hidden={(index >= skillItemsToShow ? !expandSection : false)}
            />
        </>
    })

    const programs = skillPrograms.map((info, index) =>
    {
        return <>
            <RatingCard
                expand={false}
                title={info.name}
                rating={info.rating}
                tip={<><Icon.Clock style={{ filter: 'drop-shadow(1px 1px 0px black' }} /> {info.time}</>}
                icon={info.icon}
                desc={info.description === "" || info.description == null ? 'No description for this yet...' : info.description}
                hidden={(index >= skillItemsToShow ? !expandSection : false)}
            />
        </>
    })

    return (
        <div className={expandSection ? 'section-skills-expanded' : 'section-skills'} ref={sectionRef}>
            <h1 className='title-skills'>Skills</h1>
            <p className='description-skills'>Lists that visualize my competence in different languages, disciplines, and programs.<br />
                <span className='oT scaleT'>Hover</span> an entry for further information.
            </p>

            {/* <a className='btn btn-primary mb-4' onClick={handleExpandLanguages} style={{ width: '120px', alignSelf: 'center' }}>{expandSection ? 'Collapse' : 'Expand'}</a> */}

            <div className='row justify-content-center text-center g-0'>
                <div className='col-3'>
                    <h4>Languages</h4>
                    <div className="scrollbar-thin" style={{ overflowY: languages.length > 7 ? 'auto' : 'visible', overflowX: 'clip', maxHeight: '750px', width: "fit-content", margin: '0 auto', padding: '0 1rem' }}>
                        {languages}
                    </div>
                    {skillLanguages.length > skillItemsToShow ?
                        <a className='btn-text' onClick={handleExpand} > {expandSection ? <>Show less<Icon.ChevronUp /></> : <>and {skillLanguages.length - skillItemsToShow} more <Icon.ChevronDown /></>}</a>
                        : null
                    }
                </div>
                <div className='col-3'>
                    <h4>Disciplines</h4>
                    <div className="scrollbar-thin" style={{ overflowY: disciplines.length > 7 ? 'auto' : 'visible', overflowX: 'clip', maxHeight: '750px', width: "fit-content", margin: '0 auto', padding: '0 1rem' }}>
                        {disciplines}
                    </div>
                    {skillDisciplines.length > skillItemsToShow ?
                        <a className='btn-text' onClick={handleExpand} > {expandSection ? <>Show less<Icon.ChevronUp /></> : <>and {skillDisciplines.length - skillItemsToShow} more <Icon.ChevronDown /></>}</a>
                        : null
                    }
                </div>
                <div className='col-3'>
                    <h4>Programs</h4>
                    <div className="scrollbar-thin" style={{ overflowY: programs.length > 7 ? 'auto' : 'visible', overflowX: 'clip', maxHeight: '750px', width: "fit-content", margin: '0 auto', padding: '0 1rem' }}>
                        {programs}
                    </div>
                    {skillPrograms.length > skillItemsToShow ?
                        <a className='btn-text' onClick={handleExpand} > {expandSection ? <>Show less<Icon.ChevronUp /></> : <>and {skillPrograms.length - skillItemsToShow} more <Icon.ChevronDown /></>}</a>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutSkills