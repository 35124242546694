import React from 'react'
import './Footer.css'
import reactLogo from './assets/logo_react.png'
import springLogo from './assets/logo_reactSpring.png'

const Footer = () =>
{
    return (
        <div className='footer-container'>
            <div className='container'>
                <h3 style={{textAlign: 'center'}}>Website made with:</h3>
                <div className='row text-center justify-content-center'>
                    <a href='https://react.dev/' className='col-1 btn mx-5'><img src={reactLogo} height='60px' /><h4>React</h4></a>
                    <a href='https://www.react-spring.dev/' className='col-2 btn mx-5 me-3'><img src={springLogo} height='60px' /><h4>react-spring</h4></a>
                    <a href='https://getbootstrap.com/' className='col-2 btn mx-5 me-3'><img src='/graphics/bootstrap_logo.png' height='60px' /><h4>Bootstrap</h4></a>
                </div>
            </div>
        </div>
    )
}

export default Footer