import './AboutEducation.css';
import * as Icon from 'react-bootstrap-icons';
import Timeline from './components/Timeline';
import SubjectBox from './components/SubjectBox';
import ContentBox from './components/ContentBox';
import { a, useSpring, easings } from '@react-spring/web'

import ProfilePic from './assets/NicklasBehrndtz_Billede_Kvadrat.png'

import { eduInfo } from './assets/data.js';

import Grid from './components/Grid.js';

const AboutEducation = () =>
{
    const educationBoxes = eduInfo.map(info =>
        <ContentBox key={info.subject} title={info.subject} undertitle={`${info.yearStart} - ${info.yearEnd}`}>
            <div className='row'>
                <div className='col'>
                    <h3>{info.subject}</h3>
                    <div className='overflow-y-auto scrollbar-thin' style={{ height: '280px' }}>
                        {info.description}
                    </div>
                </div>
                <div className='col-5'>
                    <img src={info.image} alt='Nicklas Behrndtz' style={{ width: '100%', borderRadius: '5px', marginBottom: '0.5rem' }}></img>
                    {info.optionalContent}
                </div>
            </div>
        </ContentBox>
    )

    // const sBackground = useSpring({
    //     from: {
    //         backgroundSize: '40px 40px',
    //         backgroundPosition: 'center',
    //     },
    //     to: {
    //         backgroundSize: '60px 60px',
    //     },
    //     config: { duration: 4500, easing: easings.easeInOutQuint },
    //     loop: { reverse: true },
    //     delay: 6500
    // });

    const [bounce1, b1] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
    const [bounce2, b2] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
    const [bounce3, b3] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));

    setTimeout(() =>
    {
        b1.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.02)' },
            loop: { reverse: true },
        })
    }, 10);

    setTimeout(() =>
    {
        b2.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.02)' },
            loop: { reverse: true },
        })
    }, 300);

    setTimeout(() =>
    {
        b3.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.02)' },
            loop: { reverse: true },
        })
    }, 600);

    return (
        <div className='section-education'>

            {/* TODO: FIX GRID SPEED(s) */}
            <Grid size={37.0} scrollSpeed={0.56} primaryColor='#00000020' secondaryColor='#fff47b' />
            <Grid size={38.0} scrollSpeed={0.55} primaryColor='#00000030' secondaryColor='transparent' />
            <Grid size={40.0} scrollSpeed={0.5} primaryColor='#00000040' secondaryColor='transparent' />
            <Grid size={48.0} scrollSpeed={0.45} primaryColor='#00000090' secondaryColor='transparent' />
            <Grid size={52.0} scrollSpeed={0.4} thickness={2} primaryColor='#00000090' secondaryColor='transparent' />
            <Grid size={56.0} scrollSpeed={0.3} thickness={2} primaryColor='#000000b0' secondaryColor='transparent' borderFadeColor='#fff47b' />

            {/* Background circles */}
            <a.div className='header-circle' id='circlePink3' style={bounce3}></a.div>
            <a.div className='header-circle' id='circlePink2' style={bounce2}></a.div>
            <a.div className='header-circle' id='circlePink1' style={bounce1}></a.div>


            <div className='container'>
                <h1 className='title-education' style={{ position: 'relative', zIndex: '1' }}>Education</h1>
                <div className='education-content-container'>
                    {/* <Timeline></Timeline> */}

                    {educationBoxes}
                </div>
            </div >
        </div >
    )
}

export default AboutEducation;