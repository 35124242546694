import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

const CustomCarousel = ({ images }) =>
{
    const slides = images.map(item => (
        <Carousel.Item>
            {(item.replace(/ .*/, '') === 'video') ? <video src={item.replace(/video /, '')} autoPlay={true} muted={true} loop={true} width={'100%'} style={{ display: 'block' }} /> : null}
            {(item.replace(/ .*/, '') === 'image') ? <img src={item.replace(/image /, '')} width={'100%'} /> : null}
            {(item.replace(/ .*/, '') === 'youtube') ? <iframe width='100%' height="315" src={item.replace(/youtube /, '')} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen /> : null}
            {/* <Carousel.Caption>
                <p>A description?</p>
            </Carousel.Caption> */}
        </Carousel.Item>
    ))

    return (
        <Carousel style={{ background: 'grey' }} interval={null} indicators={false} controls={images.length > 1}>
            {slides}
        </Carousel>
    )
}

export default CustomCarousel