import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer';

const CustomVideo = ({ src, autoPlay, muted, loop, style, className, pauseOutOfView }) =>
{
    const vidRef = useRef(null);
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: [0.25, 0.75],
        triggerOnce: false,
    });
    let [videoPromise, setVideoPromise] = useState(undefined);

    useEffect(() =>
    {
        if (inView)
        {
            if (videoPromise !== undefined)
                videoPromise.then(_ => setVideoPromise(vidRef.current.play())).catch(e => {console.log("Couldn't pause video. Error: " + e)});
            else
                setVideoPromise(vidRef.current.play());
        }
        else
        {
            if (videoPromise !== undefined)
                videoPromise.then(_ => setVideoPromise(vidRef.current.pause())).catch(e => {console.log("Couldn't pause video. Error: " + e)});
            else
                setVideoPromise(vidRef.current.pause());
        }
    })

    return (
        <div ref={ref} className={className} style={style}>
            <video ref={vidRef} src={src} autoPlay={pauseOutOfView ? false : autoPlay} muted={muted} loop={loop} style={{ width: '100%' }} />
        </div>

    )
}

export default CustomVideo