import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PopupBox = ({ children, visible, closeHandle, title = 'Modal title here' }) =>
{
    const headerFooterBackground = 'grey';

    return (
        <Modal show={visible} onHide={closeHandle} size='xl' style={{ backdropFilter: 'blur(7px)' }}> {/* Backdrop filter has to be inlined for some unknown reason. */}
            <Modal.Header closeButton style={{ background: headerFooterBackground, boxShadow: 'inset 2px 5px 20px rgba(255, 255, 255, 0.5)', textShadow: '1px 1px 2px white' }}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer style={{ background: headerFooterBackground, boxShadow: 'inset -2px -5px 20px rgba(0, 0, 0, 0.5)' }}>
                <Button variant="primary" onClick={closeHandle}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PopupBox