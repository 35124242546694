import React, { useRef, useState } from 'react'
import './ProfileStatus.css'
import * as Icon from 'react-bootstrap-icons';
import { a, useSpring, easings } from '@react-spring/web'
import Overlay from 'react-bootstrap/Overlay'

const ProfileStatus = ({className}) =>
{
    const pipSpring = useSpring({
        from: { position: 'absolute', width: '7px', height: '7px', opacity: 1, borderRadius: '100%', alignSelf: 'center', border: '1px solid #00ff00' },
        to: { width: '30px', height: '30px', opacity: 0 },
        config: { duration: 500, easing: easings.easeOutSine },
        loop: true,
        delay: 1800
    })

    const [showCopyMsg, setShowCopyMsg] = useState(false);
    const target = useRef(null);
    const email = "nickl4sxd1@gmail.com";
    function copyToClipboard(text)
    {
        navigator.clipboard.writeText(text)
        setShowCopyMsg(true);
        setTimeout(() => {setShowCopyMsg(false)}, 1000);
    }

    return (
        <div className={'status-container' + ` ${className}`}>
            <div className='row'>
                <div className='status-indicator'>
                    <div>
                        <a.div style={pipSpring}></a.div>
                        <div style={{ width: '7px', height: '7px', borderRadius: '100%', alignSelf: 'center', background: '#00ff00' }} />
                    </div>
                    Available for hire
                </div>
            </div>
            <div className='row'>
                <a className='btn btn-dark' onClick={() => { copyToClipboard(email); }} style={{textAlign: 'left'}}> {showCopyMsg ? 'copied!' : <><Icon.EnvelopeAtFill size='20' alignmentBaseline='middle' className='ms-3 me-1' /> {email}</>}</a>
                <a href='https://www.linkedin.com/in/nicklas-behrndtz/' target='_blank' className='btn btn-dark' style={{textAlign: 'left'}}><Icon.Linkedin size='20' className='ms-3 me-1' /> LinkedIn</a>
                <div className='col'></div>
            </div>
        </div >
    )
}

export default ProfileStatus