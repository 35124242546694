import React, { useState } from 'react'
import './ContentBox.css'
import ButtonHollow from './ButtonHollow.js'
import { useSpring, a } from '@react-spring/web';
import Label from './Label.js';

const ContentBox = ({ children, openByDefault = false, title = 'Title here', undertitle = 'Undertitle here'}) =>
{
    const [open, setOpen] = useState(openByDefault);

    const [backSpring, backS] = useSpring(() =>
    ({
        from: { transform: 'scale(0.0)' },
        config: { mass: 5, tension: 2000, friction: 300 }
    }));

    // Content inside the box
    let innerBox = () =>
    {
        return (
            <div className={open ? 'contentBox-inner' : 'contentBox-inner_blur'}>
                {children}
                <a.button className='btn btn-dark' onClick={handleClickClose} style={backSpring}>Close</a.button>
            </div>
        )
    }

    // The overlay "blocking" the content within; the interface used to "open" the box
    let lid = () =>
    {
        return (
            <div className={open ? 'contentBox-lid_hide' : 'contentBox-lid'}>
            
                {/* The method of responsive for these labels are probably bad practice. Keep this in mind! */}
                <Label className='d-none d-sm-none d-md-none d-lg-block' size={2} mgBot='0.5rem' color='white'>{title}</Label>
                <Label className='d-none d-sm-none d-md-none d-lg-block' size={0} color='#67df8f'>{undertitle}</Label>
                <Label className='d-lg-none' size={1} mgBot='0.5rem' color='white'>{title}</Label>
                <Label className='d-lg-none' size={0} color='#67df8f'>{undertitle}</Label>

                <a className='btn cb-btn-open' style={{ marginTop: '2rem' }} onClick={handleClickOpen}>Read more</a>
            </div>
        )
    }

    const handleClickOpen = () =>
    {
        setOpen(true);
        backS.start({
            to: { transform: 'scale(1.0)' },
            delay: 500
        });
    }

    const handleClickClose = () =>
    {
        setOpen(false);
        backS.start({
            to: { transform: 'scale(0.0)' }
        });
    }

    return (
        <div className='contentBox-container'>
            {innerBox()}
            {lid()}
        </div>
    )
}

export default ContentBox