
import React from 'react'
import './CategoryBox.css'

const CategoryBox = ({ children, iconElement }) =>
{
  return (
    <div className='catBox-container'>
      <div className='catBox-content'>
        {children}
      </div>
      <div id='catBox-sticker'>
        {iconElement}
      </div>
    </div>
  )
}

export default CategoryBox