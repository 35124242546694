import React from 'react'

function ParseHeightFromSize(size)
{
    if (size === 'xs')
        return '0.5rem';
    if (size === 'sm')
        return '1rem';
    if (size === 'md')
        return '2rem';
    if (size === 'lg')
        return '3rem';
    if (size === 'xl')
        return '4rem';
    if (size === 'xxl')
        return '6rem';
}

const Unity = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/U_Logo_White_RGB.png' title='Unity' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Blender = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/blender_logo_socket.png' title='Blender' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const AfterEffects = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/adobe_afterEffects_logo.png' title='Adobe After Effects' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Photoshop = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/adobe_photoshop_logo.png' title='Adobe Photoshop' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const GameMaker = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/GameMaker_Logo_WhiteTransparent.png' title='GameMaker Studio' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Processing = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <><img src='/graphics/processing_logo.png' title='Processing 3' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} /> Processing 3</>
};

export
{
    Unity,
    Blender,
    AfterEffects,
    Photoshop,
    GameMaker,
    Processing,
}