import React from 'react'

function ParseHeightFromSize(size)
{
    if (size === 'xs')
        return '0.5rem';
    if (size === 'sm')
        return '1rem';
    if (size === 'md')
        return '2rem';
    if (size === 'lg')
        return '3rem';
    if (size === 'xl')
        return '4rem';
    if (size === 'xxl')
        return '6rem';
        
    return '2rem';
}

const Blender = ({ size = 'md' }) =>
{
    const height = ParseHeightFromSize(size);
    return <img src='/graphics/blender_icon_512x512.png' title='Blender' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
}

const Unity = ({ size = 'md' }) =>
{
    const height = ParseHeightFromSize(size);
    return <img src='/graphics/U_Logo_White_Square_RGB.png' title='Unity' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
}

const CSharp = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/cSharp_logo.svg' title='C#' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const CPP = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/cpp_logo.png' title='C++' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const HTML = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/HTML5_logo_and_wordmark.svg' title='HTML' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const CSS = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/CSS3_logo_and_wordmark.svg' title='CSS' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const GML = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/gameMakerLanguage_logo.png' title='GML' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const JS = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/javaScript_logo.png' title='JavaScript' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Lua = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/lua_logo.png' title='Lua' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const HLSL = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <p style={{ display: 'inline-block', alignSelf: 'center', lineHeight: height, verticalAlign: 'middle', margin: '0' }}>HLSL</p>
};

const LaTeX = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/latex_logo.png' title='LaTeX' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Dart = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/dart_logo.png' title='Dart' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const UnrealEngine = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/d/da/Unreal_Engine_Logo.svg' title='Unreal Engine' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const VisualStudio = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/visualStudio_logo.png' title='Visual Studio' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const VSCode = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/visualStudioCode_logo.svg' title='Visual Studio Code' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Eclipse = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/eclipse_logo.png' title='Eclipse IDE' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const MediaEncoder = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/5/5a/Adobe_Media_Encoder_Icon.svg' title='Media Encoder' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const DaVinciResolve = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/9/90/DaVinci_Resolve_17_logo.svg' title='DaVinci Resolve' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const PremierePro = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/4/40/Adobe_Premiere_Pro_CC_icon.svg' title='Premiere Pro' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Maya = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/maya_logo.png' title='Maya' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Audacity = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/f/f6/Audacity_Logo.svg' title='Audacity' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const FL = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/fr/e/ef/FL-Studio-12-Logo.png' title='FL Studio' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const PlasticSCM = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='/graphics/plasticSCM_logo.png' title='Plastic SCM' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const GitHubDesktop = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/a/ae/Github-desktop-logo-symbol.svg' title='GitHub Desktop' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const Word = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/f/fd/Microsoft_Office_Word_%282019%E2%80%93present%29.svg' title='Microsoft Word' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const PowerPoint = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/1/16/Microsoft_PowerPoint_2013-2019_logo.svg' title='Microsoft PowerPoint' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};

const OBS = ({ size = 'md' }) =>
{
    let height = ParseHeightFromSize(size);
    return <img src='https://upload.wikimedia.org/wikipedia/commons/1/14/Open_Broadcaster_Software_Logo.png' title='OBS' style={{ alignSelf: 'center', height: height, verticalAlign: 'middle' }} />
};



export
{
    Blender,
    Unity,
    CSharp,
    CPP,
    HTML,
    CSS,
    GML,
    JS,
    Lua,
    HLSL,
    LaTeX,
    Dart,
    UnrealEngine,
    VisualStudio,
    VSCode,
    Eclipse,
    MediaEncoder,
    DaVinciResolve,
    PremierePro,
    Maya,
    Audacity,
    FL,
    PlasticSCM,
    GitHubDesktop,
    Word,
    PowerPoint,
    OBS,
}