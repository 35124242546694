import React from 'react'
import './RatingCard.css';
import RatingBar from './RatingBar';

const RatingCard = ({ desc, icon, title = 'Experience', rating = 3, tip = 'insert tip here', expand=false, hidden=false }) =>
{
    return (
        <div className={'ratingCard-container' + (expand ? ' ratingCard-container-expanded' : '')} hidden={hidden}>
            {/* <div className='row g-0'>
            </div> */}
            <div className='ratingCard-row'>
                <div className='ratingCard-col-left'>
                    {icon}
                    <p className='ratingCard-tip'>{tip}</p>
                </div>
                <div className='ratingCard-col-right'>
                    <h6>{title}</h6>
                    <RatingBar rating={rating} />
                </div>
            </div>
            <p style={{ position: 'relative', display: 'block', width: '100%', fontSize: '12px' }}>
                {desc}
            </p>
        </div>
    )
}

export default RatingCard