import React, { useInsertionEffect, useState } from 'react'


const Grid = ({ size = 40.0, thickness = 1, scrollSpeed = 1.0, primaryColor = '#000000', secondaryColor = '#fff47b', borderFadeColor }) =>
{
    // Retrieve scroll position
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () =>
    {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useInsertionEffect(() =>
    {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () =>
        {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='education-background' style={{
            backgroundPosition: `0 ${(scrollPosition / (1.0 / scrollSpeed)) % size}px`,
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',

            backgroundImage: `radial-gradient(circle, ${primaryColor} ${thickness}px, ${secondaryColor} 1px)`,
            backgroundSize: `${size}px ${size}px`,
            boxShadow: `inset 0 0 40vw 10vw ${borderFadeColor ?? secondaryColor }`
        }} />
    )
}

export default Grid