import React, { forwardRef } from 'react'
import './RatingBar.css';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const RatingBar = ({ rating = 3, segments = 5, showRatingText = true }) =>
{
    const ratingsText = ['not used', 'beginner', 'learning', 'intermediate', 'advanced', 'expert'];

    /* All blocks contained within the bar - contains overlays for additional information when hovered */
    const blocks = () =>
    {
        let output = [];
        for (let i = 0; i < segments; i++)
        {
            output.push(
                <OverlayTrigger delay={{show: '150', hide: '100'}} placement='top' overlay={<Tooltip id='button-tooltip' style={{pointerEvents: 'none'}}>{ratingsText[i+1]}</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                        <div className='ratingBar-block' {...triggerHandler} ref={ref} />
                    )}
                </OverlayTrigger>
            );
        }

        return output;
    }

    return (
        <>
            <div className='ratingBar-container' style={{ background: `linear-gradient(90deg, var(--brightYellow) ${rating * 100 / segments}%, grey ${rating * 100 / segments}%)` }}>
                {blocks()}
            </div>
            <p>{ratingsText[rating]}</p>
        </>
    )
}

export default RatingBar