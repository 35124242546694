import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import './ButtonHome.css';

function ButtonHome({ top, left })
{
    return (
        <div className="homeButton">
            <Link to={'/'} style={{top: top, left: left}}>
                <Icon.HouseFill size={28} />
            </Link >
        </div>
    )
}

export default ButtonHome;