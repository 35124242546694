import React from 'react'
import * as Icon from 'react-bootstrap-icons'
import * as Badges from '../components/Badges.js'
import * as Logos from '../components/Logos.js'

const eduInfo = [
    {
        subject: 'M.sc. in Medialogy',
        description: <p style={{ textAlign: 'left' }}>
            During my time in the Master's program, I discovered and developed my interest in numerical simulations of cars.<br />
            Three of my four semester projects revolved around some facet of vehicle simulation: tire dynamics, digital self-driving car simulation, and real-time vehicle deformation.
            The remaining semester project was part of a special program on the 3rd semester called the <a href='https://www.dadiu.dk/' target='_blank'>DADIU program</a> - an emulated game production environment.
        </p>,
        image: '/graphics/aau_create.jpg',
        yearStart: '2021', yearEnd: '2024'
    },
    {
        subject: 'B.sc. in Medialogy',
        description: <>
            <p>In the Bachelor's program, I worked on a variety of projects each semester:</p>
            <ul>
                <li key={'bscMobile'}>Mobile application development in Processing</li>
                <li key={'bscVision'}>Computer vision programming in Python</li>
                <li key={'bscVR'}>Interactive VR implementations in Unity</li>
                <li key={'bscAudio'}>Audio processing of impulses</li>
            </ul>
            <p>As a result of the broad curriculum on the Medialogy Bachelor, I've obtained a solid foundation for future projects involved with digital experiences.</p>
        </>,
        image: '/graphics/aau_create.jpg',
        yearStart: '2018', yearEnd: '2021'
    },
    {
        subject: 'High School',
        description: <p style={{ textAlign: 'left' }}>
            During my time in high school, I developed a solid foundation for the mathematics that I use on a near-daily basis.
            Participating in the 2nd year's programming course, I developed games in GameMaker Studio and Unity, one of which was later showcased to the 1st year students.
            As part of an elective course, me and 2 classmates teamed up to develop a physical lock which could be unlocked by knocking on the door with a specific pattern - a pattern which was configurable through its LCD interface.
        </p>,
        image: '/graphics/heg_aars.jpg',
        optionalContent: <a className='btn btn-primary' href='https://heguddannelser.dk/htx' target='_blank'>Click here for my interview on their website</a>,
        yearStart: '2015', yearEnd: '2018'
    },
    {
        subject: 'Web-integrator',
        description: <p style={{ textAlign: 'left' }}>
            As a young teenager, I was left unsatisfied with the traditional curriculum of school.
            This led me down the path of web development.
            <br />
            At this point in time, I had not yet tried to program so everything was a daunting task.
            However, I met people that were important for my development; persuaded me to attend high school, and got me into volunteer work.
            <br /><br />
            While I'm not using knowledge that we got taught, it was still an important step in my personal development.
        </p>,
        image: '/graphics/aalborg_techcollege.jpg',
        yearStart: '2013', yearEnd: '2015'
    },
]

const skillLanguages = [
    {
        name: 'C Sharp',
        icon: <Logos.CSharp size='lg' />,
        rating: 4,
        time: '6 years',
        description: "I have used this language extensively in hobby and university projects. I am familiar with its advanced features and can use OOP to its fullest potential.",
    },
    {
        name: 'C++',
        icon: <Logos.CPP size='lg' />,
        rating: 2,
        time: '1 year',
        description: "Carrying out simple print commands seems easy enough, and I've also used it briefly in connection with CryEngine. I have yet to utilize the language on a large project.",
    },
    {
        name: 'HTML',
        icon: <Logos.HTML size='lg' />,
        rating: 3,
        time: '3 years',
        description: "A simple language that I've used as part of my formal education and for this website. I don't consider myself an expert in the language - just a casual user of it."
    },
    {
        name: 'CSS',
        icon: <Logos.CSS size='lg' />,
        rating: 4,
        time: '3 years',
        description: "I like making thing look good so I always enjoyed learning and using CSS. I am still learning and have started to find myself making intelligent informed decisions in CSS."
    },
    {
        name: 'GML',
        icon: <Logos.GML size='lg' />,
        rating: 2,
        time: '1 year',
        description: "During the high school programming course, I used this language in GameMaker to build a variety of games.",
    },
    {
        name: 'JavaScript',
        icon: <Logos.JS size='lg' />,
        rating: 3,
        time: '2 year',
        description: "Used to create a React website and, in a different format, a variety of Processing applications for Arduino and Android.",
    },
    {
        name: 'Lua',
        icon: <Logos.Lua size='lg' />,
        rating: 1,
        time: '1 year',
        description: 'This is a mandatory language to use if you want to get in contact with the more complex parts of the ComputerCraft mod for Minecraft. I never fully learned the language.'
    },
    {
        name: 'HLSL',
        icon: <Logos.HLSL size='lg' />,
        rating: 2,
        time: '2 years',
        description: "A language used to make shaders for virtual objects. I have used this in connection with GameMaker and Unity. I never became a power user due to the availability of visual shader editors."
    },
    {
        name: 'LaTeX',
        icon: <Logos.LaTeX size='lg' />,
        rating: 3,
        time: '5 years',
        description: "Used extensively to write papers of consistent quality in University."
    },
    {
        name: 'Dart',
        icon: <Logos.Dart size='lg' />,
        rating: 3,
        time: '1 year',
        description: "I have used this with Flutter to make an Android application. The workflow is similar to other dynamically typed languages such as JavaScript or Python."
    },
]

const skillDisciplines = [
    {
        name: 'Systems Design',
        icon: <p>SD</p>,
        rating: 4,
        time: <hr />,
        description: "Having created a variety of applications, I have acquired great insight into how you most effectively design the architecture for a piece of software.",
    },
    {
        name: 'Game Design',
        icon: <p>GD</p>,
        rating: 4,
        time: <hr />,
        description: "As an avid game developer, I consider myself a great companion for discussing game design. Creating a user experience requires an eye at all levels of analysis."
    },
    {
        name: '3D Math',
        icon: <p>3DM</p>,
        rating: 4,
        time: <hr />,
        description: "Vectors and matrices are a must for creating most types of games. Through high school, university, and hobby projects, I have gained a great understanding of this area of mathematics."
    },
    {
        name: 'Motion Graphics',
        icon: <p>MG</p>,
        rating: 3,
        time: <hr />,
        description: <p>I have acquired a level of skill within this via the work I have done for <a className="btn-text" href='https://www.youtube.com/channel/UCSCujQpRxWDXuhUroxH-Sfw'>my YouTube</a> videos.</p>
    },
    {
        name: '3D Modelling',
        icon: <p>3DMD</p>,
        rating: 3,
        time: <hr />,
        description: "As I have worked on a variety of 3D game projects, I consider myself to be at least average at modelling in 3D. I've modelled cars, characters, and other objects.",
    },
    {
        name: 'Psychology',
        icon: <p>PSY</p>,
        rating: 2,
        time: <hr />,
        description: "I am not formerly trained in this topic but still find it an interesting subject. I have read books and papers regarding this.",
    },
    {
        name: 'Philosophy',
        icon: <p>PHI</p>,
        rating: 2,
        time: <hr />,
        description: "In high school my eyes were opened up to philosophy. I have since then been very interested in the topic and intrigued by metaphysical discussions.",
    },
    {
        name: 'Tools Programming',
        icon: <p>TOP</p>,
        rating: 3,
        time: <hr />,
        description: "I have a decent level of experience with tools development (at hobby and study level). I have developed a variety of tools in Unity, and I have also messed a tiny bit with .bat-files",
    },
]

const skillPrograms = [
    {
        name: 'Unity',
        icon: <Logos.Unity size='lg' />,
        rating: 3,
        time: '7 years',
        description: "This is the game engine I have used to create most of my game projects, university or otherwise. I consider myself a strong user of the engine across most of its features.",
    },
    {
        name: 'Photoshop',
        icon: <Badges.Photoshop size='lg' />,
        rating: 4,
        time: '13 years',
        description: "Ever since I was a teenager, this has been my software of choice for creating image assets. I am familiar with most, if not all, its basic tools and have also acquired some more nieche tricks throughout the years."
    },
    {
        name: 'Blender',
        icon: <Logos.Blender size='lg' />,
        rating: 3,
        time: '5 years',
        description: "A fantastic piece of 3D software. I have used Blender to create, rig, animate, and render 3D assets for games, animations, and videos."
    },
    {
        name: 'After Effects',
        icon: <Badges.AfterEffects size='lg' />,
        rating: 3,
        time: '4 years',
        description: "Using its layer-based approach, I've used this program to create a variety of effects for my YouTube videos (e.g. animated text, color adjustments, and clip-to-clip transitions)"
    },
    {
        name: 'Unreal Engine',
        icon: <Logos.UnrealEngine size='lg' />,
        rating: 2,
        time: '1 year',
        description: "I have used this engine for a couple of small-scale projects in order to learn its capabilities. I never got around to C++ scripting, and made use of the nodes in Blueprints.",
    },
    {
        name: 'GameMaker Studio',
        icon: <Logos.GML size='lg' />,
        rating: 2,
        time: '1 year',
        description: "Despite no longer using it, this was the first engine I used to make games. I made multiple games in this engine in high school.",
    },    
    {
        name: 'Visual Studio 2019',
        icon: <Logos.VisualStudio size='lg' />,
        rating: 3,
        time: '6 years',
        description: "Throughout my years programming, I have used this as my main IDE for scripting. I never got that advanced with the software, but its Intellisense were very useful."
    },
    {
        name: 'Visual Studio Code',
        icon: <Logos.VSCode size='lg' />,
        rating: 3,
        time: '2 years',
        description: "The IDE I am now currently using. Its fast performance and extension-based platform is what led me to switch VS 2019 for this."
    },
    {
        name: 'Eclipse IDE',
        icon: <Logos.Eclipse size='lg' />,
        rating: 1,
        time: '1 year',
        description: "As part of my self study in C++, I used this IDE to write, debug, and compile my C++ programs."
    },
    {
        name: 'Media Encoder',
        icon: <Logos.MediaEncoder size='lg' />,
        rating: 2,
        time: '1 year',
        description: "I always tried to avoid using this software as it seems redundant for my purposes. However, it has been useful from time to time when exporting to specific formats (e.g. .webm)"
    },
    {
        name: 'DaVinci Resolve',
        icon: <Logos.DaVinciResolve size='lg' />,
        rating: 3,
        time: '1 year',
        description: "My video editing software of choice. I use this program because of its features and performance."
    },
    {
        name: 'Premiere Pro',
        icon: <Logos.PremierePro size='lg' />,
        rating: 3,
        time: '1 year',
        description: "The first video editing software I used. Its inconsistent performance led me to seek better options."
    },
    {
        name: 'Maya',
        icon: <Logos.Maya size='lg' />,
        rating: 1,
        time: '1 year',
        description: "I used this program during a course in university. However, I much prefer Blender."
    },
    {
        name: 'Audacity',
        icon: <Logos.Audacity size='lg' />,
        rating: 3,
        time: '3 years',
        description: "This is the program I use to edit audio. I have got decent experience with the basic effect options and know my way around the program."
    },
    {
        name: 'FL Studio',
        icon: <Logos.FL size='lg' />,
        rating: 3,
        time: '2 years',
        description: "When I was a teenager, I used to experiment with making music. I made some music that I was proud of but never something that rivaled anyone. I'm not a sound guy."
    },
    {
        name: 'Plastic SCM',
        icon: <Logos.PlasticSCM size='lg' />,
        rating: 2,
        time: '2 years',
        description: "To collaborate with a large group of people in Unity, Plastic SCM was used for version control during my DADIU course on my Master's. I'm not familiar with the advanced features, but I can merge my branch to main."
    },
    {
        name: 'GitHub Desktop',
        icon: <Logos.GitHubDesktop size='lg' />,
        rating: 1,
        time: '1 year',
        description: "I have never used this software extensively, but I know how to merge, fetch, pull, and push."
    },
    {
        name: 'Microsft Word',
        icon: <Logos.Word size='lg' />,
        rating: 4,
        time: '15 years',
        description: "As everyone else I know, this is the software that was used for every assignment in school. During high school I picked up on some of the more advanced features."
    },
    {
        name: 'Microsft PowerPoint',
        icon: <Logos.PowerPoint size='lg' />,
        rating: 4,
        time: '13 years',
        description: "Like everyone else, this is the software that we used to make slides for school presentations. I know how to get fancy with it."
    },
    {
        name: 'OBS',
        icon: <Logos.OBS size='lg' />,
        rating: 2,
        time: '1 year',
        description: <p>I don't have the greatest experience with this streaming software. I have only ever made <a className='btn-text'>one stream</a> with it. I still have more to learn here.</p>
    },
]

const portInfo = [
    {
        previewImage: 'video /graphics/simulationRepository_preview.mp4',
        title: 'Simulation Repository',
        subtitle: 'A local multiplayer car arena game.',
        information: <>
            <h5>Main Features:</h5>
            <ul>
                <li>Nuanced vehicle movement from a top-down perspective</li>
                <li>Weapon pickups</li>
                <li>Environmental twist in the final round</li>
            </ul>
            <hr />
            <p>
                The goal of this project was to create a multiplayer game that revolved around vehicle combat, similar to the gameplay seen in Twisted Metal or Motorsiege: Warriors of Primetime.
                The game sees two players fight it out in a contained arena. The arena contains various weapons: Laser Cannon, Shotgun, and Rocket Launcher.
                In addition, each player starts with a machine gun which serves as the default weapon when running out of ammo for the others.
                The weapons that are located inside the arena each have their own level. This weapon level is bound to each player.
                Each weapon is leveled up 50% whenever it is picked up. At increasing levels, weapons become exponentially stronger which prompts players to go for as many weapon pickups as possible - a stronger weapon means a higher advantage against the opponent.
            </p>
        </>,
        contribution: "All code, effects, 3D models, and animation. 50% of the sounds and textures.",
        programs: [<Badges.Unity />, <Badges.Blender />, 'Audacity', <Badges.AfterEffects />],
        languages: [<Logos.CSharp />],
        timeSpent: 'On and off for a year',
        itchUrl: 'https://sweatix.itch.io/simulation-repository-demo',
        images: ['video /graphics/simulationRepository_preview.mp4', 'video /graphics/simulationRepository_arenaCondition.mp4', 'image /graphics/simulationRepository_obliterate.png'],
        icon: <Icon.Brush size={'30'} />,
    },
    {
        previewImage: 'video /graphics/youtube_preview.mp4',
        title: 'YouTube Animation',
        subtitle: "2D/3D animation made for my videos",
        information: <>
            <p>
                Shortly after entering university, I started doing some video editing in my spare time; videos of my buddies and I playing games and talking.
                As I found editing fun, I wanted to go beyond what I was capable of and decided to add some trivial effects to the videos e.g. text and such.
                It wasn't long before I got a good handle on After Effects and Blender to create more advanced effects for the clips in my videos.
            </p>
            <hr />
            <p>
                What you see here is a collection of all the animation I made for my YouTube channel in the years 2019-2024.
                Links for videos containg 2D/3D animation is available below.
            </p>
            <b>3D Animation (1 link pr. animation):</b>
            <ul>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/SY9HKoK_sFQ?si=Cj8NWDjHL0dqD-V8&t=7'>The Equation of Fun | Intro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/SY9HKoK_sFQ?si=wCnYkh--OWZ2lOga&t=434'>The Equation of Fun | Calculator</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/SY9HKoK_sFQ?si=GtWENqsMU3S56wG9&t=709'>The Equation of Fun | Outro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/oSB5LFA0CXE?si=pkt_aNqT767QSuMS'>Leftovers | Intro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/oSB5LFA0CXE?si=OAPKKoPEZWu6qNdC&t=605'>Leftovers | Outro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/mhbXzSXTgJs?si=TF9hRe-WQilrS-mD'>Sublime FM | Intro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/mhbXzSXTgJs?si=EjHoUbIyNn9lJnqa&t=245'>Sublime FM | Radio 1/2</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/mhbXzSXTgJs?si=azs0WrA8pto_8xQk&t=330'>Sublime FM | Radio 2/2</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/CCaSwXRVHh8?si=QNtO6fh6ky1-DuVq&t=15'>Bleeding Dry | Intro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/CCaSwXRVHh8?si=njy8QGNpfDbC1w4h&t=250'>Bleeding Dry | Counting</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/dsYdYLp9lgQ?si=v6zRii9zWE0Vub0Y'>RL Randomizer #3 | Intro</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/dsYdYLp9lgQ?si=FyyfOQp3V5tRz5gO&t=161'>RL Randomizer #3 | Transition</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/dsYdYLp9lgQ?si=kmKhUPCxQ2ugrLzJ&t=382'>RL Randomizer #3 | TV</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/dsYdYLp9lgQ?si=CY_CJWRhDoOUTpsx&t=742'>RL Randomizer #3 | Hotdog</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/dsYdYLp9lgQ?si=D90dIH7iB4V0e5QR&t=778'>RL Randomizer #3 | Outro</a></li>
            </ul>
            <b>2D Animation (1 link pr. video):</b>
            <ul>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/B28nd0J5CAE'>Krewdie & Sweatix | Left is First</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/MLMj2pC-wqs'>Nick & Sven | A Howling Moon</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/WA9tjJzio9g'>Nick & Sven | We have dinner tonight!</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/g84yuILet60'>Nick & Sven | We're jacked!</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/-0wzi32YxXU'>Nick & Sven | Crossbows & Sabers</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/e1QeWKYNdDc'>Nick & Sven | Battlefield 1042</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/bMqC65O9aMQ'>Nick & Sven | Ambulance of Ownage</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/d7tJCt6QzCs'>Sweaty & Krewdie | Oni-Sama</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/53lHnI7b0Wc'>Nick & Sven | The forgotten game</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/qMmUu9q-3HA'>Hyper Scape | Season 1 | Fosterstilling</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/Li2z9jTlzzU'>Deep Rock Galactic | The Creeps</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/fRp3goLEpEk'>Hyper Scape Beta | Bøllerne</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/7n9Vv260PUM'>A Bad Omen</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/6HnG9EY-baE'>Nick & Sven | Risk of Rain 2 (Artifacts Update)</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/q83WuJVoFHQ'>TIME 4 A RIOT | Modern Warfare 2019</a></li>
                <li><Icon.Youtube color='red' /> <a href='https://youtu.be/HG6TD6BF7as'>ONE HIT WONDER | Modern Warfare 2019</a></li>
            </ul>
        </>,
        contribution: "All animation, motion graphics, and editing.",
        programs: [<Badges.Blender />, <Badges.Photoshop />, <Badges.AfterEffects />],
        images: ['video /graphics/youtube_animation3D_01.mp4', 'video /graphics/youtube_animation3D_02.mp4', 'video /graphics/youtube_animation3D_03.mp4', 'video /graphics/youtube_animation3D_04.mp4', 'video /graphics/youtube_animation2D_01.mp4'],
        icon: <Icon.Brush size={'30'} />,
    },
    {
        previewImage: 'video /graphics/battleCannons_preview.mp4',
        title: 'Battle Cannons',
        subtitle: 'A head-to-head 2D turn-based tank fighting game',
        information: <>
            <h5>Main Features:</h5>
            <ul>
                <li>Physics-based tank movement</li>
                <li>Randomy generated destructible terrain</li>
                <li>A wide arsenal of weapons</li>
            </ul>
            <hr />
            <p>
                A head-to-head local multiplayer game that sees two tanks battle it out on a destructable battlefield.
                The idea for the game is heavily inspired by Worms and Shellshock Live. To add a new element to the game, the tanks are physically simulated.
                All wheels are connected to spring joints and have their own grip; climbing hills means gaining momentum beforehand.
                <br /><br />
                The game has 8 different weapon types with a set amount of uses:
                <ul>
                    <li>Cannon</li>
                    <li>Artillery</li>
                    <li>Grenade</li>
                    <li>Bouncer</li>
                    <li>Cluster Shell</li>
                    <li>Shotgun</li>
                    <li>Flare</li>
                    <li>Cluster Bomb</li>
                </ul>
            </p>
        </>,
        contribution: "All code, effects, sprites, sounds and textures.",
        programs: [<Badges.Unity />, <Badges.Photoshop />, 'Audacity'],
        languages: ['C#'],
        timeSpent: '1-2 months',
        itchUrl: 'https://sweatix.itch.io/battle-cannons',
        images: ['video /graphics/battleCannons_preview.mp4', 'image /graphics/battleCannons_01.png'],
        icon: <Icon.Brush size={'30'} />,
    },
    {
        previewImage: 'video /graphics/RftL_preview.mp4',
        title: 'Run for their Lives',
        subtitle: 'A game about saving lives \n The MED6 2021 winner for best fitting theme.',
        information: <>
            <p>
                As part of the 6th semester on the Medialogy Bachelor program, me and 2 other students created the game Run for their Lives.
                The prompt for this game idea was the course's requirement of being related to one of EU's humanitarian goals.
                In denmark, we have a Hjerteløber (English: Heartrunner) app which will ping users when a nearby individual who is in need of a defribillator is present.
                The app then shows the individual's location and the corresponding publically available defribillator kit.
                This game emulates this real context and gamifyes it in order to persuade players to become a heartrunner.
                <br /><br />
                When playing the game, the player will be shown the location of people in distress.
                The objective of the game is to save as many people as possible within the time limit.
                To save a person, the player must first pickup a single-use defribillator from one of three locations.
                The defribillator stations are not revealed to the player;
                the player must learn the in-game world and the location of these defribillators to optimize their routes and increase their score.
            </p>
        </>,
        contribution: "70% of the code, 70% of the 3D models, 100% of the animation and effects.",
        programs: [<Badges.Unity />, <Badges.Blender />, <Badges.Photoshop />, 'Audacity'],
        languages: [<Logos.CSharp />],
        timeSpent: '1-2 weeks',
        itchUrl: 'https://sweatix.itch.io/run-for-their-lives',
        images: ['video /graphics/RftL_preview.mp4', 'image /graphics/RftL_01.png', 'image /graphics/RftL_02.png', 'image /graphics/RftL_03.png'],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: 'video /graphics/needForDrift_preview.mp4',
        title: 'Need for Drift',
        subtitle: 'A top-down 2D car game about drifting and head-to-head vehicle combat',
        information: <>
            <h5>Main Features:</h5>
            <ul>
                <li>A "unique" steering and collision model</li>
                <li>Rocket boosts, lasers, and rockets</li>
                <li>Camera shake shader</li>
            </ul>
            <hr />
            <p>
                The game sees two players fight it out in a contained arena. Each player has access to two weapons: a laser cannon, and a rocket launcher.
                The laser cannon heats up when used and will overheat. The rocket launcher has a set amount of uses which will refill after some time has passed.
                Along with these weapons, each player can use their boost meter to massively increase their speed.<br />
                As the name of the game has it, the car can be put into drift mode which will make the car have less grip thus making the car drift.
                <br /><br />
                When making this game, special effort was put into the steering model which, at the time, was a massive task as I had yet to fully employ vectors in practice.
                I also played around with shaders and made a camera shake shader which applies a directional blur to the screen when impacting an obstacle.
            </p>
        </>,
        contribution: "All code, effects, sprites, sounds and textures.",
        programs: [Badges.GameMaker, Badges.Photoshop, 'Audacity'],
        languages: ['GML', 'HLSL'],
        timeSpent: '~6 weeks',
        itchUrl: 'https://sweatix.itch.io/need-for-drift',
        images: ['video /graphics/needForDrift_preview.mp4', 'image /graphics/needForDrift_01.png', 'image /graphics/needForDrift_02.png'],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: 'image /graphics/gun_banner.png',
        title: 'Galactic Ultra Nebula',
        subtitle: 'A game made as part of the DADIU program.',
        information: <>
            <p>
                <h5>The game</h5>
                Galactic Ultra Nebula (GUN) is a roguelike 3rd person shooter. In the game, the player starts with a small revolver as their only gun. This revolver is then presented with upgrade choices after each stage.
                In the final stage, a boss (The Maw) is fought and killed to win the game.
                <br /><br />
                <h5>Context & contribution</h5>
                This game was made in a team made up of 17 students from the DADIU program where I was assigned the role of Programmer<br />
                At the end of the program, six weeks are allotted to each team in which a game is created to its completion.
                My primary job on this project was to implement enemy behaviour into the game. I created a modular system to allow the enemy designer to iterate on enemy behaviour without having to constantly consult me.
                In the latter half of this six-week project I collaborated with the game designers to try and increase the quality of the final product.
                Some high-to-low poly modelling, rigging, and animation was also performed as part of creating the boss encounter.
            </p>
        </>,
        contribution: "All code pertaining to enemy behaviour.",
        programs: [Badges.Unity, Badges.Blender],
        languages: ['C#'],
        timeSpent: '6 weeks',
        itchUrl: 'https://dadiu.itch.io/galactic-ultra-nebula',
        images: ['image /graphics/gun_01.png', 'image /graphics/gun_02.gif', 'image /graphics/gun_03.png'],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: 'video /graphics/oitc_preview.mp4',
        title: 'One in the Chamber',
        subtitle: 'A 2019 GMTK Game Jam entry.',
        information: <>
            <b>The game jam prompt: </b><p>Only One</p>
            <hr />
            <h5>Gameplay</h5>
            <p>
                This game tries to answer the <b>only one</b> prompt by limiting the ammunition of a single weapon to one bullet.
                This one bullet is reusable due to the gun's ability to recall it - multiple shots are now possible.
                The game takes on a horror-like theme as you descent into an area filled with darkness.
                To illuminate your surroundings you must use the muzzle flash of your gun.
                <br /><br />
                Along the way, zombies and ghosts will be encountered to stop and kill you in your tracks.
            </p>
            <hr />
            <h5>Reflection</h5>
            <p>
                As is often the case with game jam games, there are things you would want to improve before releasing it to the world.
                For One in the Chamber I regret overusing darkness as a gameplay element.
                It's a cool experience to use your shots as a flashlight but it quickly becomes a frustrating element of the experience.
                It would have been better to use darkness sparingly and keep the rest of the game dim instead of pure black.
                <br /><br />
                Other small bugs are present as well but are minor compared to the design of the game.
            </p>
        </>,
        contribution: "All code, effects, lighting setup, and animation.",
        programs: [Badges.Unity, Badges.Photoshop, 'Audacity'],
        languages: ['C#'],
        timeSpent: '48 hours',
        itchUrl: 'https://sweatix.itch.io/one-in-the-chamber',
        images: ['video /graphics/oitc_preview.mp4', 'image /graphics/oitc_01.png'],
        icon: <Icon.Brush size={'30'} />,
    },
    {
        previewImage: 'image /graphics/MilkyWavesGame.png',
        title: 'Milky Wavez',
        subtitle: 'An implementation from the 1st semester on Medialogy',
        information: <>
            <p>
                Made in Processing, this mobile application tries to tackle the problem of improving sleep - the theme of the semester project.
                The project was carried out by a group of 7 students. The majority of the implementation work was done by me.
                <hr />
                The mobile application attempts to improve sleep, or make it easier to fall asleep, by lowering your heart rate.
                The application does this by having the user hold their thumb on a spaceship whose sinusoidal trajectory oscillates at a low heart rate frequency.
                The hypothesis is that focusing on slow movement, while also performing this same slow movement, will lower the heart rate of the user thus making them calm and sleepy.
                <br /><br />
                In addition to the main feature, settings that allow the user to swap colors in the game are also available in order to make the app accesible to colorblind individuals.
            </p>
        </>,
        contribution: "80% of the code.",
        programs: [Badges.Processing],
        timeSpent: '3 weeks',
        images: ['image /graphics/MilkyWavesFrontPage.png', 'image /graphics/MilkyWavesGame.png', 'image /graphics/MilkyWavesSetting.png'],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: 'video /graphics/escapement_preview.mp4',
        title: 'Escapement',
        subtitle: 'A VR game from the 5th semester on Medialogy',
        information: <>
            <p>
                To verify the utility of <i>color signifiers</i>, this implementation places the user in a VR escape room.
            </p>
        </>,
        contribution: "80% of the code, 50% of the 3D models, and 100% of the sounds.",
        programs: [Badges.Unity, Badges.Blender],
        languages: ['C#'],
        timeSpent: '~3 weeks',
        images: ['video /graphics/escapement_preview.mp4'],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: '',
        title: 'Tire Simulation',
        subtitle: 'A tire simulation project from the 7th semester on Medialogy',
        information: <>
            <h5>The project</h5>
            <i>An investigation into the potential non-linear relationship between the perceived realism and empirical accuracy of tire models</i>
            <hr />
            <p>Two things inspired the direction of this project:</p>
            <ul>
                <li>My passion for vehicle dynamics</li>
                <li>The uncanny valley of human likeness in art and robotics<br />(mentioned by my supervisor(s))</li>
            </ul>
            <p style={{ textAlign: 'justify' }}>
                The paper tries to find a correlation between perceived realism and empirical
                accuracy of tire models. The correlation that is being investigated is, in
                theory, similar to that of the uncanny valley. Three unique tire
                models are implemented and tested in a 3D virtual environment. The tire
                models are: Magic Formula, TMeasy model, and Rocket League model.
                Two experiments were performed, one of which was designed to measure
                the aforementioned correlation. The study concludes with being unable
                to deny the null-hypothesis due to a lack of evidence for a direct
                correlation. A simultaneous increase between friction and perceived realism
                is instead apparent in all three models suggesting a possible correlation
                between the two.
            </p>
        </>,
        contribution: "All code.",
        timeSpent: '~6 weeks',
        programs: [Badges.Unity, Badges.Blender],
        languages: ["C#"],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: '',
        title: 'Softbody Vehicle Physics',
        subtitle: "A softbody simulation project from the 10th semester on Medialogy",
        information: <>
            <h5>The project</h5>
            <i>Exploring and comparing the utility of different softbody methods in performing real-time simulations of vehicle crashes</i>
            <hr />
            <p>
                Limited research exists that explores real-time softbody models in the context of vehicle deformation.
                This report performs a surface-level overview of the different types of models that exist for real-time deformation.
                The finite element method (FEM) is studied and explained and the architecture used to implement the model is described.
                Due to complications faced with the implementation of the FEM, the position-based dynamics method with
                oriented particles is evaluated in the context of vehicle collisions; the comparison between several models for
                deformable solid was not obtained. The project does not contribute anything to the broader computer graphics community,
                but has served as a great platform for personal development. Avenues for future work is suggested to entice more research
                within this subject, and a project that develops on the idea presented by this work is suggested.
            </p>
        </>,
        contribution: "80% of the code.",
        timeSpent: '8 weeks',
        programs: [Badges.Unity, Badges.Blender],
        languages: ["C#"],
        icon: <Icon.Book size={'30'} />,
    },
    {
        previewImage: 'video /graphics/dnp10_preview.mp4',
        title: 'DNP #10',
        subtitle: "A 3D animation for Dansk Netparty's video intro",
        information: <>
            <p>
                As a long time volunteer in the Dansk Netparty event, I got the opportunity to make a 3D animation as part of the event's tradtional intro video.
                This was my first attempt at making a <i>full</i> animation from start to finish.
                <br /><br />
                As part of this project I started by storyboarding the animation with a fellow volunteer. Modelling, texturing, rigging, animation, and rendering was then performed.
                <br /><br />
                <b>DISCLAIMER:</b> I was not involved with audio synchronization, motion graphics and final render of the video in its entirety.
            </p>
        </>,
        contribution: "All modelling, animation, rigging, texturing, and rendering.",
        programs: [Badges.Blender, Badges.Photoshop],
        images: ['youtube https://www.youtube.com/embed/n23ib3P78gk?si=yu-tRXhqd63vYoOm&autoplay=1&controls=0'],
        icon: <Icon.Flag size={'30'} />,
    },
    {
        previewImage: '',
        title: 'DNP #11 | Cancelled',
        subtitle: "A 3D animation for Dansk Netparty's video intro",
        information: <>
            <p>
                After having contributed to the prior Dansk Netparty event intro, I once again worked on an animation - this time with a bigger scope and more experience.
                Unfortunately, the event was cancelled due to <b>COVID-19</b>. As of March 2024, there still hasn't been an event since then.
                <br /><br />
                As part of this project I started by storyboarding the animation with a fellow volunteer. Modelling, texturing, rigging, animation, and rendering was then performed.
                <br /><br />
                Not all animation for this project was created as the event was cancelled, and there is no sound. This is merely here for posterity.
            </p>
        </>,
        contribution: "All modelling, animation, rigging, texturing, and rendering.",
        programs: [Badges.Blender, Badges.Photoshop],
        images: ['youtube https://www.youtube.com/embed/hJGZOrQY39k?si=dttveZjYiAi4Y-Lt&autoplay=1&controls=0', 'youtube https://www.youtube.com/embed/3668caz987U?si=2IBA4HgxjVJrwprK&controls=0'],
        icon: <Icon.Flag size={'30'} />,
    },
    {
        previewImage: 'video /graphics/execution_preview.mp4',
        title: 'EXECUTION',
        subtitle: "A local Tekken 8 launch event",
        information: <>
            <p>
                EXECUTION was a Tekken 8 launch party event which I organized together with <a href='https://www.exzentriq.dk/'>Exzentriq</a>.
            </p>
            <p>
                The event consisted of people playing the, at the time, newly released Tekken 8 and competing in a tournament to find the best player attending.
                As this was one of the first 'fighting game only'-events in Northern Jutland, a modest 7 people showed up.
                Nevertheless, there was effort put into marketing the event and make the event an experience over a simple LAN-event.
                A stream was up during the whole event which followed the progress of the tournament.
            </p>
            <p>
                All of the graphical work was created by myself along with the OBS software setup - hardware was supplied by Exzentriq.
                Because this was my first ever stream, we had some audio and video issues throughout the stream.
                However, I think it still went alright.
            </p>
        </>,
        contribution: "All of the graphical work, 50% of the event organization, and 50% of the marketing.",
        programs: [Badges.AfterEffects, Badges.Photoshop, 'OBS'],
        images: ['youtube https://www.youtube.com/embed/K6tv-ALqBjA?si=tNrAYQ_CtEdq2Lh6&autoplay=1', 'youtube https://www.youtube.com/embed/NrwbJUZTYnc?si=Xg0db3Mg1ICl3w7e', 'image /graphics/EXECUTION_Flyer_RGB_English.png'],
        icon: <Icon.Flag size={'30'} />,
    },
]

export
{
    eduInfo,
    skillLanguages,
    skillDisciplines,
    skillPrograms,
    portInfo
}