import React, { useState } from 'react'

import * as Icon from 'react-bootstrap-icons';
import ProfilePic from './assets/NicklasBehrndtz_Billede_Kvadrat.png';
import ButtonHollow from './components/ButtonHollow.js';
import ButtonHome from './components/ButtonHome.js';

import './AboutHeader.css'

import { useTrail, a, useSpring, easings } from '@react-spring/web'
import ProfileStatus from './components/ProfileStatus.js';

const Trail = ({ open, children, className }) =>
{
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
        config: { mass: 20, tension: 2000, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 40,
        height: open ? 110 : 0,
        from: { opacity: 0, x: 20, height: 0 },
        delay: 500
    })
    return (
        <div className={className}>
            {trail.map(({ height, ...style }, index) => (
                <a.div key={index} className={'trailsText'} style={style}>
                    <a.div style={{ height }}>{items[index]}</a.div>
                </a.div>
            ))}
        </div>
    )
}

const AboutHeader = () =>
{
    const pSpring = useSpring({
        from: { x: 50, opacity: 0, fontWeight: '500' },
        to: { x: 0, opacity: 1 },
        delay: 3200
    });

    const imgSpring = useSpring({
        from: { opacity: 0, y: '300px' },
        to: { opacity: 1, y: '0px' },
        config: {
            mass: 40,
            tension: 1000,
            friction: 220
        },
        delay: 2200
    });

    const [open, setOpen] = useState(true);

    const [bounce1, b1] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
    const [bounce2, b2] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));
    const [bounce3, b3] = useSpring(() => ({ from: { transform: 'scale(1.0)' }, config: { mass: 20, duration: 3000, easing: easings.easeInOutSine } }));

    setTimeout(() =>
    {
        b1.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.01)' },
            loop: { reverse: true },
        })
    }, 50);

    setTimeout(() =>
    {
        b2.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.03)' },
            loop: { reverse: true },
        })
    }, 100);

    setTimeout(() =>
    {
        b3.start({
            from: { transform: 'scale(1.0)' },
            to: { transform: 'scale(1.04)' },
            loop: { reverse: true },
        })
    }, 150);

    function getAge(dateString)
    {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
        {
            age--;
        }
        return age;
    }

    return (
        <div className='header'>

            {/* Background circles */}
            <a.div className='header-circle' id='circle1' style={bounce1}></a.div>
            <a.div className='header-circle' id='circle2' style={bounce2}></a.div>
            <a.div className='header-circle' id='circle3' style={bounce3}></a.div>

            <ButtonHome top={'30px'} left={'30px'} />

            <div className='header-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {/* <div className='headline-about'>Creating <span className='oT'>digital experiences</span> with <span className='oT'>flair</span> and <span className='oT'>nuance</span></div> */}
                            <Trail className='d-md-none d-sm-none d-none d-lg-block' open={open}>
                                <span>Creating <span className='oT'>digital</span></span>
                                <span><span className='oT'>experiences</span> with </span>
                                <span><span className='oT'>flair</span> and <span className='oT'>nuance</span></span>
                            </Trail>
                            <h1 className='d-md-block d-lg-none d-xl-none d-xxl-none' style={{textShadow: '1px 1px 4px black'}}>
                                Creating <span className='oT'>digital</span> <span className='oT'>experiences</span> with <span className='oT'>flair</span> and <span className='oT'>nuance</span>
                            </h1>
                            <a.p className='about-subline' style={pSpring}>
                                Hi! Welcome to my website.
                                <br />
                                My name is Nicklas Behrndtz. I'm a {getAge("1997/08/29")} year old programmer and graphical designer from Denmark.
                                As hobbies, I enjoy playing games, making games, creating videos, and recently ... making websites.
                                <br />
                                As a creative individual, I am intrigued by the eccentric and chaotic side of nature; creative works that go outside the conventions of their media of choice - not because they want to, but because they have to.
                                I'm meticulous and a chronic thinker who considers all the options available to solve a given problem.
                                Depending on the day I can either be energetic or contained and focused.
                                <br /><br />
                                In the sections below you will find my portfolio and educational background.
                                <br />
                            </a.p>
                            <ProfileStatus className='d-lg-none' />
                        </div>
                        <a.div className='col-4 d-md-none d-sm-none d-none d-lg-block' style={imgSpring}>
                            <img src={ProfilePic} alt='Nicklas Behrndtz' className='profilePic' style={{ marginBottom: '1rem' }} />
                            <ProfileStatus />
                        </a.div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHeader