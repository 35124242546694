import React from 'react'
import './Label.css'

const Label = ({ children, className, size = 0, color = 'black', mgTop = '0', mgBot = '0', shadow = '1px 1px 1px black' }) =>
{
    let font_size = '24px';

    if (size === 0)
        font_size = '18px';
    else if (size === 1)
        font_size = '24px';
    else if (size === 2)
        font_size = '32px';

    return (
        <div className={'label' + ` ${className}`} style={{
            fontSize: font_size,
            color: color,
            textShadow: shadow,
            marginTop: mgTop, marginBottom: mgBot
        }}>
            {children}
        </div>
    )
}

export default Label